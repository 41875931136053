const MenuList = [
    {
        id: 1,
        title: "SUPERPANCHO",
        simplePrice: "$1600",
        completePrice: "",
    },
    {
        id: 2,
        title: "HAMBURGUESA",
        simplePrice: "$3600",
        completePrice: "$4100",
    },
    {
        id: 3,
        title: "HAMBURGUESA DOBLE",
        simplePrice: "$5200",
        completePrice: "$5700",
    },
    {
        id: 4,
        title: "HAMBURGUESA TRIPLE",
        simplePrice: "$7000",
        completePrice: "$7500",
    },
    {
        id: 5,
        title: "HAMBURGUESA DE POLLO",
        simplePrice: "$3600",
        completePrice: "$4100",
    },
    {
        id: 6,
        title: "HAMB. DE POLLO DOBLE",
        simplePrice: "$5200",
        completePrice: "$5700",
    },
    {
        id: 7,
        title: "MEGA HAMBURGUESA",
        simplePrice: "$4400",
        completePrice: "$4900",
    },
    {
        id: 8,
        title: "MEGA HAMB. DOBLE",
        simplePrice: "$5900",
        completePrice: "$6400",
    },
    {
        id: 9,
        title: "MEGA HAMB. TRIPLE",
        simplePrice: "$7400",
        completePrice: "$7900",
    },
    {
        id: 10,
        title: "HAMBURGUESA GOURMET",
        simplePrice: "$5300",
        completePrice: "$5800",
    },
    {
        id: 11,
        title: "HAMB. GOURMET DOBLE",
        simplePrice: "$7100",
        completePrice: "$7600",
    },
    {
        id: 12,
        title: "MILANESA DE CARNE",
        simplePrice: "$5200",
        completePrice: "$5700",
    },
    {
        id: 13,
        title: "MILANESA DE POLLO",
        simplePrice: "$5200",
        completePrice: "$5700",
    },
    {
        id: 14,
        title: "NUGGETS DE POLLO",
        simplePrice: "$5000",
        completePrice: "",
    },
    {
        id: 15,
        title: "POLLO GRILL",
        simplePrice: "$5200",
        completePrice: "$5700",
    },
    {
        id: 16,
        title: "LOMITO",
        simplePrice: "$6100",
        completePrice: "$6600",
    },
    {
        id: 17,
        title: "PAPAS TRADICIONALES",
        simplePrice: "$2900",
        completePrice: "",
    },
    {
        id: 18,
        title: "PAPAS NOISETTE",
        simplePrice: "$3200",
        completePrice: "",
    },
    {
        id: 19,
        title: "PAPAS SMILES",
        simplePrice: "$3200",
        completePrice: "",
    },
    {
        id: 20,
        title: "PAPAS CORTE CASERO",
        simplePrice: "$3200",
        completePrice: "",
    },

];

export default MenuList;